// Thanks to https://github.com/jeff-collins/ment.io

export class TributeRange {
  constructor(tribute) {
    this.tribute = tribute
    this.tribute.range = this
  }

  getDocument() {
    let iframe
    if (this.tribute.current.collection) {
      iframe = this.tribute.current.collection.iframe
    }

    if (!iframe) {
      return document
    }

    return iframe.contentWindow.document
  }

  positionMenuAtCaret(scrollTo) {
    let menu = this.tribute.menu
    if(menu.popper)
      menu.popper.update();

  }

  get menuContainerIsBody() {
    return this.tribute.menuContainer === document.body || !this.tribute.menuContainer;
  }


  selectElement(targetElement, path, offset) {
    let range
    let elem = targetElement

    if (path) {
      for (var i = 0; i < path.length; i++) {
        elem = elem.childNodes[path[i]]
        if (elem === undefined) {
          return
        }
        while (elem.length < offset) {
          offset -= elem.length
          elem = elem.nextSibling
        }
        if (elem.childNodes.length === 0 && !elem.length) {
          elem = elem.previousSibling
        }
      }
    }
    let sel = this.getWindowSelection()

    range = this.getDocument().createRange()
    range.setStart(elem, offset)
    range.setEnd(elem, offset)
    range.collapse(true)

    try {
      sel.removeAllRanges()
    } catch (error) {}

    sel.addRange(range)
    targetElement.focus()
  }

  replaceTriggerText(text, requireLeadingSpace, hasTrailingSpace, originalEvent, item) {
    let info = this.getTriggerInfo(true, hasTrailingSpace, requireLeadingSpace, this.tribute.allowSpaces, this.tribute.autocompleteMode)

    if (info !== undefined) {
      let context = this.tribute.current
      let replaceEvent = new CustomEvent('tribute-replaced', {
        detail: {
          item: item,
          instance: context,
          context: info,
          event: originalEvent,
        }
      })

      if (!this.isContentEditable(context.element)) {
        let myField = this.tribute.current.element
        let textSuffix = typeof this.tribute.replaceTextSuffix == 'string'
            ? this.tribute.replaceTextSuffix
            : ' '
        text += textSuffix
        let startPos = info.mentionPosition
        let endPos = info.mentionPosition + info.mentionText.length + (textSuffix === '' ? 1 : textSuffix.length)
        if (!this.tribute.autocompleteMode) {
          endPos += info.mentionTriggerChar.length - 1
        }
        myField.value = myField.value.substring(0, startPos) + text +
          myField.value.substring(endPos, myField.value.length)
        myField.selectionStart = startPos + text.length
        myField.selectionEnd = startPos + text.length
      } else {
        // add a space to the end of the pasted text
        let textSuffix = typeof this.tribute.replaceTextSuffix == 'string'
            ? this.tribute.replaceTextSuffix
            : '\xA0'
        text += textSuffix
        let endPos = info.mentionPosition + info.mentionText.length
        if (!this.tribute.autocompleteMode) {
          endPos += info.mentionTriggerChar.length
        }
        this.pasteHtml(text, info.mentionPosition, endPos)
      }

      context.element.dispatchEvent(new CustomEvent('input', { bubbles: true }))
      context.element.dispatchEvent(replaceEvent)
    }
  }

  pasteHtml(html, startPos, endPos) {
    let range, sel
    sel = this.getWindowSelection()
    range = this.getDocument().createRange()
    range.setStart(sel.anchorNode, startPos)
    range.setEnd(sel.anchorNode, endPos)
    range.deleteContents()

    let el = this.getDocument().createElement('div')
    el.innerHTML = html
    let frag = this.getDocument().createDocumentFragment(),
        node, lastNode
    while ((node = el.firstChild)) {
      lastNode = frag.appendChild(node)
    }
    range.insertNode(frag)

    // Preserve the selection
    if (lastNode) {
      range = range.cloneRange()
      range.setStartAfter(lastNode)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }

  getWindowSelection() {
    if (this.tribute.collection.iframe) {
      return this.tribute.collection.iframe.contentWindow.getSelection()
    }

    return window.getSelection()
  }

  getNodePositionInParent(element) {
    if (element.parentNode === null) {
      return 0
    }

    for (var i = 0; i < element.parentNode.childNodes.length; i++) {
      let node = element.parentNode.childNodes[i]

      if (node === element) {
        return i
      }
    }
  }

  getContentEditableSelectedPath(ctx) {
    let sel = this.getWindowSelection()
    let selected = sel.anchorNode
    let path = []
    let offset

    if (selected != null) {
      let i
      let ce = selected.contentEditable
      while (selected !== null && ce !== 'true') {
        i = this.getNodePositionInParent(selected)
        path.push(i)
        selected = selected.parentNode
        if (selected !== null) {
          ce = selected.contentEditable
        }
      }
      path.reverse()

      // getRangeAt may not exist, need alternative
      offset = sel.getRangeAt(0).startOffset

      return {
        selected: selected,
        path: path,
        offset: offset
      }
    }
  }

  getTextPrecedingCurrentSelection() {
    let context = this.tribute.current,
        text = ''

    if (!this.isContentEditable(context.element)) {
      let textComponent = this.tribute.current.element;
      if (textComponent) {
        let startPos = textComponent.selectionStart
        if (textComponent.value && startPos >= 0) {
          text = textComponent.value.substring(0, startPos)
        }
      }

    } else {
      let selectedElem = this.getWindowSelection().anchorNode

      if (selectedElem != null) {
        let workingNodeContent = selectedElem.textContent
        let selectStartOffset = this.getWindowSelection().getRangeAt(0).startOffset

        if (workingNodeContent && selectStartOffset >= 0) {
          text = workingNodeContent.substring(0, selectStartOffset)
        }
      }
    }

    return text
  }

  getLastWordInText(text) {
    var wordsArray;
    if (this.tribute.autocompleteSeparator) {
      wordsArray = text.split(this.tribute.autocompleteSeparator);
    } else {
      wordsArray = text.split(/\s+/);
    }
    var wordsCount = wordsArray.length - 1;
    return wordsArray[wordsCount];
  }

  getTriggerInfo(menuAlreadyActive, hasTrailingSpace, requireLeadingSpace, allowSpaces, isAutocomplete) {
    let ctx = this.tribute.current
    let selected, path, offset

    if (!this.isContentEditable(ctx.element)) {
      selected = this.tribute.current.element
    } else {
      let selectionInfo = this.getContentEditableSelectedPath(ctx)

      if (selectionInfo) {
        selected = selectionInfo.selected
        path = selectionInfo.path
        offset = selectionInfo.offset
      }
    }

    let effectiveRange = this.getTextPrecedingCurrentSelection()
    let lastWordOfEffectiveRange = this.getLastWordInText(effectiveRange)

    if (isAutocomplete) {
      return {
        mentionPosition: effectiveRange.length - lastWordOfEffectiveRange.length,
        mentionText: lastWordOfEffectiveRange,
        mentionSelectedElement: selected,
        mentionSelectedPath: path,
        mentionSelectedOffset: offset
      }
    }

    if (effectiveRange !== undefined && effectiveRange !== null) {
      let mostRecentTriggerCharPos = -1
      let triggerChar

      this.tribute.collection.forEach(config => {
        let c = config.trigger
        let idx = config.requireLeadingSpace ?
            this.lastIndexWithLeadingSpace(effectiveRange, c) :
            effectiveRange.lastIndexOf(c)

        if (idx > mostRecentTriggerCharPos) {
          mostRecentTriggerCharPos = idx
          triggerChar = c
          requireLeadingSpace = config.requireLeadingSpace
        }
      })

      if (mostRecentTriggerCharPos >= 0 &&
          (
            mostRecentTriggerCharPos === 0 ||
              !requireLeadingSpace ||
              /\s/.test(
                effectiveRange.substring(
                  mostRecentTriggerCharPos - 1,
                  mostRecentTriggerCharPos)
              )
          )
         ) {
        let currentTriggerSnippet = effectiveRange.substring(mostRecentTriggerCharPos + triggerChar.length,
                                                             effectiveRange.length)

        triggerChar = effectiveRange.substring(mostRecentTriggerCharPos, mostRecentTriggerCharPos + triggerChar.length)
        let firstSnippetChar = currentTriggerSnippet.substring(0, 1)
        let leadingSpace = currentTriggerSnippet.length > 0 &&
            (
              firstSnippetChar === ' ' ||
                firstSnippetChar === '\xA0'
            )
        if (hasTrailingSpace) {
          currentTriggerSnippet = currentTriggerSnippet.trim()
        }

        let regex = allowSpaces ? /[^\S ]/g : /[\xA0\s]/g;

        this.tribute.hasTrailingSpace = regex.test(currentTriggerSnippet);

        if (!leadingSpace && (menuAlreadyActive || !(regex.test(currentTriggerSnippet)))) {
          return {
            mentionPosition: mostRecentTriggerCharPos,
            mentionText: currentTriggerSnippet,
            mentionSelectedElement: selected,
            mentionSelectedPath: path,
            mentionSelectedOffset: offset,
            mentionTriggerChar: triggerChar
          }
        }
      }
    }
  }

  lastIndexWithLeadingSpace (str, trigger) {
    let reversedStr = str.split('').reverse().join('')
    let index = -1

    for (let cidx = 0, len = str.length; cidx < len; cidx++) {
      let firstChar = cidx === str.length - 1
      let leadingSpace = /\s/.test(reversedStr[cidx + 1])

      let match = true
      for (let triggerIdx = trigger.length - 1; triggerIdx >= 0; triggerIdx--) {
        if (trigger[triggerIdx] !== reversedStr[cidx-triggerIdx]) {
          match = false
          break
        }
      }

      if (match && (firstChar || leadingSpace)) {
        index = str.length - 1 - cidx
        break
      }
    }

    return index
  }

  isContentEditable(element) {
    return element.nodeName !== 'INPUT' && element.nodeName !== 'TEXTAREA'
  }

}
