import { Action, ActionGroup } from "../actions";

export class Navigation {
  constructor(collection) {
    this._collection = collection;
    this._actionGroup = new ActionGroup({
      actions: [
        this.createPrevious(),
        this.createCurrentItem(),
        this.createNext(),
        this.createClose()
      ]
    });
  }

  get element() {
    return this._actionGroup.element;
  }

  destroy() {
    this._actionGroup.destroy();
  }

  createPrevious() {
    const action = new Action({
      text: "Previous",
      classes: [(this._collection.canPrevious ? null : "disabled")],
      icon: {
        name: "angle-left"
      }
    });

    this._collection.onChange((evt) => {
      const coll = evt.detail.collection;
      if(coll.canPrevious)
        action.element.classList.remove("disabled");
      else
        action.element.classList.add("disabled");
    });

    action.element.onclick = () => { this._collection.previous(); };

    return action;
  }

  createNext() {
    const action = new Action({
      text: "Next",
      classes: [(this._collection.canNext ? null : "disabled")],
      icon: {
        left: false,
        name: "angle-right"
      }
    });

    this._collection.onChange((evt) => {
      const coll = evt.detail.collection;
      if(coll.canNext)
        action.element.classList.remove("disabled");
      else
        action.element.classList.add("disabled");
    });

    action.element.onclick = () => { this._collection.next(); };

    return action;
  }

  createCurrentItem() {
    const textProvider = (coll) => `${coll.index + 1} of ${coll.total}`;
    const action = new Action({
      text: textProvider(this._collection),
    });

    this._collection.onChange((evt) => {
      const coll = evt.detail.collection;
      action.element.innerHTML = textProvider(coll);
    });

    return action;
  }

  createClose() {
    return new Action({
      attributes: {
        "data-dismiss": "modal"
      },
      icon: {
        name: "times"
      }
    });
  }
}
