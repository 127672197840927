class Dropdown {
  constructor(element) {
    this.element = element
  }

  start() {
    this.attachEventListeners()
  }

  isAttached() {
    return this.element.isConnected
  }

  attachEventListeners() {
    this.element.addEventListener('click', this.onClick)
  }

  destroy() {
    this.element.removeEventListener('click', this.onClick)
    this.element = null
  }

  onClick(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    const e = evt.target
    const $trigger = $(e)
    const $dropdown = $trigger.parent()
    const $dropdownMenu = $trigger.next()
    // close all dropdown menu
    $dropdown
      .siblings()
      .find('.dropdown-menu')
      .removeClass('show')

    $dropdownMenu.toggleClass('show')

    $trigger
      .parents('.nav-item')
      .one('hidden.bs.dropdown', () => {
        $dropdownMenu.removeClass('show')
      })
  }
}

export class NestedDropdowns {
  constructor() {
    this._dropdowns = []
  }

  static start() {
    if(!window.Dropdowns) {
      window.Dropdowns = new this();
      window.Dropdowns.start();
    }
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.create();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  cleanup() {
    this._dropdowns = this._dropdowns.filter((dropdown) => {
      if (!dropdown.isAttached()) {
        dropdown.destroy();
        return false
      } else {
        return true
      }
    })
  }

  create() {
    const elements = document.querySelectorAll(
      '.dropdown-menu [data-toggle="dropdown"]'
    )
    for(const element of elements) {
      if(this._dropdowns.find((d) => d.element == element))
        continue

      const dropdown = new Dropdown(element)
      dropdown.start()
      this._dropdowns.push(dropdown)
    }
  }
}
