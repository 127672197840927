export class Form {
  constructor() {
  }

  static start() {
    if(!window.Form) {
      window.Form = new this();
      window.Form.start();
    }
  }

  start() {
    this.attachEventListeners();
  }

  refresh(form) {
    form.action += "?_type=refresh"

    if (form.dataset.validated == "true") {
      form.action += "&_form_validated=true";
    }
    form.requestSubmit()
  }

  attachEventListeners() {
    document.addEventListener("reset", (event) => {
      const form = event.target
      for (const field of form.elements) {
        $(field).val(null).trigger("change")
      }
      event.preventDefault();
    })

    document.addEventListener("turbo:submit-start", (event) => {
      const target = event.target
      const form = event.target
      const formSubmission = event.detail.formSubmission
      const fetchRequest = formSubmission.fetchRequest
      const fetchOptions = fetchRequest.fetchOptions
      const submitter = formSubmission.submitter

      if (event.target instanceof HTMLFormElement) {
        const body = this.isBodyInit(fetchOptions.body) ? fetchOptions.body : new URLSearchParams()
        const method = this.determineFetchMethod(submitter, body, form)

        if (!/get/i.test(method)) {
          if (/post/i.test(method)) {
            body.delete("_method")
          } else {
            body.set("_method", method)
          }

          fetchOptions.method = "post"
        }

        for (const field of target.elements) {
          if (field.iMask && field.value != "") {
            body.set(field.name, field.iMask.typedValue)
          }
        }
      }
    })
  }

  determineFetchMethod(submitter, body, form) {
    const formMethod = this.determineFormMethod(submitter)
    const overrideMethod = body.get("_method")
    const method = form.getAttribute("method") || "get"

    if (typeof formMethod == "string") {
      return formMethod
    } else if (typeof overrideMethod == "string") {
      return overrideMethod
    } else {
      return method
    }
  }

  determineFormMethod(submitter) {
    if (submitter instanceof HTMLButtonElement || submitter instanceof HTMLInputElement) {
      // Rails 7 ActionView::Helpers::FormBuilder#button method has an override
      // for formmethod if the button does not have name or value attributes
      // set, which is the default. This means that if you use <%= f.button
      // formmethod: :delete %>, it will generate a <button name="_method"
      // value="delete" formmethod="post">. Therefore, if the submitter's name
      // is already _method, it's value attribute already contains the desired
      // method.
      if (submitter.name === '_method') {
        return submitter.value
      } else if (submitter.hasAttribute("formmethod")) {
        return submitter.formMethod
      } else {
        return null
      }
    } else {
      return null
    }
  }

  isBodyInit(body) {
    return body instanceof FormData || body instanceof URLSearchParams
  }
}
