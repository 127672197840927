import { Utils } from './utils'
class TurbolinksPermanentValues {
  static init() {
    if(!window.turbolinksPermanentValues) {
      window.turbolinksPermanentValues = new this()
      window.turbolinksPermanentValues.init()
    }
  }

  init() {
    document.addEventListener("turbo:before-fetch-request", (event)=> {
      const src = Utils.lastRenderedLocation()
      const srcPath = src.pathname
      const dstUrl = event.detail.url
      const dstPath = dstUrl.pathname
      if(src.href == dstUrl || srcPath == dstPath) {
        const headers = event.detail.fetchOptions.headers
        document.querySelectorAll('[id][data-rx-permanent-value]')
          .forEach((element) => {
            const value = element.getAttribute['data-rx-permanent-value'];
            headers[`X-RX-PV-${element.id}`] = value
          })
      }
    })
  }
}

export { TurbolinksPermanentValues }
