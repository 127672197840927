import { Controller } from "./controller"
import { TabManager } from "./tab-manager"

const controller = new Controller();
const tabManager = new TabManager();

export default {
  Controller,
  TabManager,
  controller,
  tabManager,
  start: function() {
    controller.start();
    tabManager.start();
    window.tabManager = tabManager;
  }
};
