export class Utils {
  /**
   * Detect if current screen size wider than our toggleScreen
   * refer to our media-breakpoint-up("md")
   */
  static isToggleScreenUp () {
    return window.matchMedia('(min-width: 768px)').matches
  }

  static isToggleScreenXlUp () {
    return window.matchMedia('(min-width: 1200px)').matches
  }

  /**
   * Detect if current screen size lower than our toggleScreen
   * refer to our media-breakpoint-down("md")
   */
  static isToggleScreenDown () {
    return window.matchMedia('(max-width: 767.98px)').matches
  }

  static lastRenderedLocation() {
    const url = window.Turbo.session.view.lastRenderedLocation.toString()
    return (new URL(url))
  }

  static round(number, digits) {
    const p = Math.pow(10, digits);
    return Math.round((number + Number.EPSILON) * p) / p;
  }
}
