class AutoRefresh {
  static init() {
    if(!window.autoRefresh) {
      window.autoRefresh = new this()
      window.autoRefresh.init()
    }
  }

  init() {
    document.addEventListener("turbo:load", () => {
      const page = document.querySelector(".page")
      if(!page)
        return;

      const refreshSeconds = page.dataset.refreshSeconds;
      if(!refreshSeconds)
        return;

      const ms = refreshSeconds * 1000;

      const href = window.location.href

      setTimeout(() => {
        const chref = window.location.href;
        if(href != chref)
          return;

        this.refresh(new URL(window.location.href))
      }, ms);
    })
  }

  refresh(location) {
    const form = document.createElement("form")

    const type = "hidden"
    for (const [name, value] of location.searchParams) {
      form.append(
        Object.assign(
          document.createElement("input")
          , { type, name, value }
        )
      )
    }

    form.append(
      Object.assign(
        document.createElement("input"),
        { type, name: "_type", value: "_auto_refresh" })
    )

    const action = Object.assign(location, { search: "" })
    form.setAttribute("data-turbo", "true")
    form.setAttribute("data-turbo-frame", "remote-modal")
    form.setAttribute("data-turbo-stream", "")
    form.setAttribute("action", action.href)
    form.setAttribute("hidden", "")
    form.setAttribute("method", "POST");

    document.body.appendChild(form)
    form.addEventListener(
      "turbo:submit-end",
      () =>  form.remove(),
      { once: true }
    )
    requestAnimationFrame(() => form.requestSubmit())
  }
}

export { AutoRefresh }
