export class ScrollUtils {
  static isScrolled(element) {
    const scrolledAttr = element.attributes['data-scrolled']
    if(!scrolledAttr) return
    return scrolledAttr.value == "scrolled"
  }

  static setScrolled(element) {
    element.setAttribute("data-scrolled", "scrolled")
  }

  static scrollInto(element) {
    try {
      element.scrollIntoViewIfNeeded(true)
    }
    catch(e) {
      element.scrollIntoView({behaviour: 'smooth'})
    }
  }

  static scrollBottom(element) {
    element.scrollTop = element.scrollHeight
  }
}
