import {Tribute} from "./tribute";

export class Controller {

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    if(this._element) {
      if(this._element.isConnected)
        return;
      else {
        this._tribute.detach(this._element);
        this._tribute = null;
        this._element = null;
      }
    }


    this._element = document.getElementById('comments-publisher-text');

    if(!this._element)
      return;

    this._tribute = new Tribute({
      values: (text, callback) => {
        this.mentionsLookup(text, users => callback(users));
      },
      lookup: function (user, mentionText) {
        return user.username + user.name;
      },
      fillAttr: 'username',
      menuShowMinLength: 2,
      menuContainer: document.getElementById('mentions-container'),
      menuItemTemplate: function (itm) {
        return `<img src="${itm.original.gravatar}"> ${itm.original.name}`;
      }
    });
    this._tribute.attach(this._element)

  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => { this.init(); })
  }

  mentionsLookup(text, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var data = JSON.parse(xhr.responseText);
          callback(data);
        } else if (xhr.status === 403) {
          callback([]);
        }
      }
    };
    const url = this._element.form.action;
    xhr.open("GET", `${url}/mentions?q=${text}`, true);
    xhr.send();
  }
}
