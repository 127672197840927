const deepmerge = require("deepmerge");

export class ActionGroup {
  constructor(opts={}) {
    this.opts = opts;
    this.opts.classes = this.opts.classes ?? [];
    this.opts.actions = this.opts.actions ?? [];
    this.opts.classes.push("btn-group");
    this._element = this.create();
  }

  get element() {
    return this._element;
  }

  create() {
    const el = document.createElement('div');
    this.opts.classes.forEach((c) => el.classList.add(c));
    this._actions = [];
    this.opts.actions.forEach((action) => {
      this._actions.push(action);
      el.appendChild(action.element);
    });

    return el;
  }

  destroy() {
    this._element.remove();
    this._actions.forEach((a) => a.destroy());
    this._actions = null;
    this._element = null;
  }
}
