import { Action, ActionGroup } from "../actions";

export class Toolbar {
  constructor(controller) {
    this._controller = controller;
    this._actionGroup = new ActionGroup({
      actions: [
        this._createRotateLeft(),
        this._createRotateRight(),
        this._createPreviousPage(),
        this._createCurrentPage(),
        this._createNextPage(),
        this._createZoomOut(),
        this._createZoomIn()
      ]
    });

    this._container = this._createContainer();
    this._controller._footer.appendChild(this._container);
    this._controller.refresh();
  }

  destroy() {
    if(this._actionGroup) {
      this._actionGroup.destroy();
      this._actionGroup = null;
    }

    if(this._container){
      this._container.remove();
      this._container = null;
    }

    this._controller = null;
  }

  _createContainer() {
    const el = document.createElement("div");
    el.classList.add(
      "btn-toolbar",
      "w-100",
      "border-top",
      "justify-content-center"
    );
    el.appendChild(this._actionGroup.element);
    return el;
  }

  get viewer() {
    return this._controller._viewer;
  }

  get viewerLoaded() {
    return this.viewer && this.viewer.isLoaded()
  }

  _createRotateLeft() {
    return this._createAction({
      iconName: 'undo',
      enabled: () => this.viewerLoaded,
      visible: () => this.viewerLoaded && this.viewer.supportsRotate(),
      onClick: () => this.viewer.rotateLeft()
    });
  }


  _createRotateRight() {
    return this._createAction({
      iconName: 'redo',
      enabled: () => this.viewerLoaded,
      visible: () => this.viewerLoaded && this.viewer.supportsRotate(),
      onClick: () => this.viewer.rotateRight()
    });
  }

  _createZoomIn() {
    return this._createAction({
      iconName: 'search-plus',
      enabled: () => this.viewerLoaded && this.viewer.canZoomIn(),
      visible: () => this.viewerLoaded && this.viewer.supportsZoom(),
      onClick: () => this.viewer.zoomIn()
    });
  }

  _createZoomOut() {
    return this._createAction({
      iconName: 'search-minus',
      enabled: () => this.viewerLoaded && this.viewer.canZoomOut(),
      visible: () => this.viewerLoaded && this.viewer.supportsZoom(),
      onClick: () => this.viewer.zoomOut()
    });
  }

  _createPreviousPage() {
    return this._createAction({
      iconName: 'chevron-up',
      enabled: () => this.viewerLoaded && this.viewer.canPrevious(),
      visible: () => this.viewerLoaded && this.viewer.supportsPaging(),
      onClick: () => this.viewer.previousPage()
    });
  }

  _createNextPage() {
    return this._createAction({
      iconName: 'chevron-down',
      enabled: () => this.viewerLoaded && this.viewer.canNext(),
      visible: () => this.viewerLoaded && this.viewer.supportsPaging(),
      onClick: () => this.viewer.nextPage()
    });
  }


  _createCurrentPage() {
    const textProvider = () => {
      if(this.viewerLoaded) {
        const state = this.viewer._state;
        return `${state.page}/${state.numPages}`;
      }
      return "";
    };

    const action = new Action({
      text: textProvider(),
      classes: ["d-none"]
    });

    this._controller.onChange(() => {
      if(this.viewerLoaded && this.viewer.supportsPaging()) {
        action.element.classList.remove("d-none");
        action.element.innerHTML = textProvider();
      }
      else {
        action.element.classList.add("d-none");
      }
    });

    return action;
  }

  _createAction(opts = {}) {
    const fnEnabled = opts.enabled;
    const fnVisible = opts.visible;
    const fnOnClick = opts.onClick;
    const action = new Action({
      classes: [
        (fnEnabled() ? null : "disabled"),
        (fnVisible() ? null : "d-none")
      ],
      icon: {
        name: opts.iconName
      }
    });

    this._controller.onChange((evt) => {
      if(fnVisible()) {
        action.element.classList.remove("d-none");
        if(fnEnabled())
          action.element.classList.remove("disabled");
        else
          action.element.classList.add("disabled");
      }
      else {
        action.element.classList.add("d-none");
      }
    });

    if(fnOnClick) {
      action.element.onclick = () => {
        if(fnVisible() && fnEnabled())
          fnOnClick();
      }
    }

    return action;
  }
}
