export class DisableElements {
  constructor() {
  }

  static start() {
    if(!window.DisableElements) {
      window.DisableElements = new this();
      window.DisableElements.start();
    }
  }

  start() {
    this.attachEventListeners();
  }

  attachEventListeners() {
    $(document).on('turbo:submit-start', (event) => {
      const evt = event.originalEvent;
      const target = evt.currentTarget
      const form = evt.detail.formSubmission.formElement
      const activeElement = target.activeElement
      if(activeElement) {
        this.disableElement(activeElement)
        form._initiator = activeElement
      }
    })
    $(document).on('turbo:submit-end', (event) => {
      const evt = event.originalEvent;
      const form = evt.detail.formSubmission.formElement
      const activeElement = form._initiator
      if(activeElement) {
        this.enableElement(activeElement)
        activeElement.removeAttribute("disabled")
        form._initiator = null
      }
    })
  }

  disableElement(element) {
    if(element.dataset["disable"] == "true") {
      element.classList.add("disabled")
    }
  }

  enableElement(element) {
    if(element.dataset["disable"] == "true" &&
       element.classList.contains("disabled")) {
      element.classList.remove("disabled")
    }
  }
}