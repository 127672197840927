const StackedMenu = require('exports-loader?StackedMenu!stacked-menu');

import { Utils } from '../utils'

export class Menu {
  constructor(backdrop) {
    this.backdrop = backdrop
    this.wasCompact = true
  }

  init() {
    this.createMenu()
    this.update()
  }

  attachEventListeners() {
    window.addEventListener('resize', () => this.update())
    document.addEventListener("turbo:before-fetch-request", (event)=> {
      const srcPath = Utils.lastRenderedLocation().pathname
      const dstPath = event.detail.url.pathname
      if(srcPath == dstPath) {
        const headers = event.detail.fetchOptions.headers
        const value = (this.isCompact ? "compact" : "full")
        headers["X-Menu-State"] = value
      }
    })
    document.addEventListener("turbo:load", () => { this.init() })
    $(document).on('click', '[data-toggle="aside-menu"]', (e) => {
      e.preventDefault()
      this.toggle()
    })
  }

  createMenu() {
    this._menu = document.getElementById("stacked-menu")
    this._app = document.querySelector(".app")
    if(!this.menu) return
    if(this.menu.stackedMenu) {
      return
    }
    const isCompact = this.isCompact

    this.menu.stackedMenu = new StackedMenu({
      compact: isCompact,
      hoverable: isCompact
    })
  }

  get menu() {
    return this._menu
  }

  get app() {
    return this._app
  }

  get isCompact() {
    return this.wasCompact ?? this.app.classList.contains('has-compact-menu')
  }

  get isFullWidth() {
    return this.app.classList.contains('has-fullwidth')
  }

  collapse() {
    this.toggle(true)
  }

  expand() {
    this.toggle(false)
  }

  toggle(collapse) {
    if(!this.menu.stackedMenu) return

    const compact = collapse ?? !this.isCompact

    this.menu.stackedMenu.compact(compact).hoverable(compact)
    if(compact)
      this.app.classList.add('has-compact-menu')
    else
      this.app.classList.remove('has-compact-menu')
  }

  update() {
    if(!this.menu) return
    if(!this.menu.stackedMenu) return

    const stackedMenu = this.menu.stackedMenu

    if (!Utils.isToggleScreenUp() || this.isFullWidth) {
      stackedMenu.compact(false).hoverable(false)
      this.wasCompact = this.isCompact
      this.app.classList.remove('has-compact-menu')
    }
    else {
      const isCompact = this.isCompact
      this.wasCompact = null
      if(isCompact) {
        this.app.classList.add('has-compact-menu')
        stackedMenu.closeAllMenu();
      }
      else {
        this.app.classList.remove('has-compact-menu')
      }
      stackedMenu.compact(isCompact).hoverable(isCompact)
    }
  }
}
