import { ActionGroup } from '../actions';
import { Navigation } from './navigation';
import { DocumentActions } from './document-actions';

export class Toolbar {
  constructor(collection) {
    this._element = this.create(collection);
  }

  get element() {
    return this._element;
  }

  create(collection) {
    const el = document.createElement("div");
    el.classList.add("btn-toolbar", "w-100", "border-bottom");
    this._documentActions = new DocumentActions(collection);
    el.appendChild(this._documentActions.element);
    this._navigationActions = new Navigation(collection);
    el.appendChild(this._navigationActions.element);
    return el;
  }

  destroy() {
    if(this._documentActions) {
      this._documentActions.destroy();
      this._documentActions = null;
    }
    if(this._navigationActions) {
      this._navigationActions.destroy();
      this._navigationActions = null;
    }
    this._element = null;
  }
}
