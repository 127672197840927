export class KeyboardShortcuts {
  constructor(component) {
    this._component = component;
    this._component.container.addEventListener("keydown", (e) => {
      this.handleKeydown(e)
    });
  }

  destroy() {
    this._component = null;
  }

  handleKeydown(event){
    if (event.defaultPrevented) {
      return; // Do nothing if event already handled
    }

    switch(event.code) {
    case "ArrowLeft":
      this._component.collection.previous();
      break;
    case "ArrowRight":
      this._component.collection.next();
      break;
    default:
      return;
    }

    event.preventDefault();
  }
}
