export class Backdrop {
  constructor() {
    this.selector = ".aside-backdrop"
  }

  init() {
    this.create()
  }

  create() {
    const app = document.querySelector('.app')
    if(!app) return
    if(this.getBackdrop()) return

    const backdrop = document.createElement("div")
    backdrop.classList.add("aside-backdrop")
    app.prepend(backdrop)
  }

  getBackdrop() {
    return document.querySelector(this.selector)
  }

  show() {
    const backdrop = this.getBackdrop();
    if(!backdrop) return;
    backdrop.classList.add("show")
  }

  hide() {
    const backdrop = this.getBackdrop();
    if(!backdrop) return;
    backdrop.classList.remove("show")
  }
}
