const deepmerge = require("deepmerge");

export class Action {
  defaultOptions() {
    return {
      elementType: "div",
      text: '',
      classes: ["btn", "btn-light", "text-dark"],
      attributes: {},
      icon: {
        left: true,
        classes: [
          "fa",
          "text-primary"
        ],
        name: ''
      }
    }
  }

  constructor(opts) {
    this.opts = deepmerge(this.defaultOptions(), opts);
    this.opts.icon.classes.push(`fa-${this.opts.icon.name}`);

    if(this.opts.text) {
      if(this.opts.icon.left)
        this.opts.icon.classes.push("mr-2");
      else
        this.opts.icon.classes.push("ml-2");
    }

    this._element = this.create();
  }

  get element() {
    return this._element;
  }

  create() {
    const elOpts = this.opts;
    const iconOpts = this.opts.icon;

    const el = document.createElement(elOpts.elementType);
    this.addClasses(el, elOpts);
    this.setAttributes(el, elOpts);
    const icon = document.createElement("i");
    this.addClasses(icon, iconOpts);
    const text = document.createElement("span");
    text.appendChild(document.createTextNode(elOpts.text));


    if(iconOpts.name) {
      text.classList.add("d-none", "d-sm-inline");
      if(iconOpts.left) {
        el.appendChild(icon);
        el.appendChild(text);
      }
      else {
        el.appendChild(text);
        el.appendChild(icon);
      }
    }
    else {
      el.appendChild(text);
    }

    return el;
  }

  addClasses(el, opts) {
    opts.classes.forEach((c) => el.classList.add(c));
  }

  setAttributes(el, opts) {
    const attrs = opts.attributes;
    Object.keys(attrs).forEach((k) => el.setAttribute(k, attrs[k]));
  }

  destroy() {
    this._element.onclick = null;
    this._element = null;
  }
}
