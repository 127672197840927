export class Scrollbar {
  constructor(element) {
    this._lastScrollTop = 0;
    this._element = element;
    const suppressX = this.getDataAttribute('suppress-x', false);
    const suppressY = this.getDataAttribute('suppress-y', false);
    const wheelPropagation = this.getDataAttribute('wheel-propagation', true);
  }


  getDataAttribute(name, defaultValue) {
    const key = `data-scrollbar-${name}`
    const attr = this._element.attributes[key]

    if(attr)
      return attr.value;
    else
      return defaultValue;
  }

  isAttached() {
    return this._element.isConnected;
  }

  updateLastScrollTop() {
    this._lastScrollTop = this._element.scrollTop;

  }

  update() {
    this._element.scrollTop = this._lastScrollTop;
  }

  destroy() {
    this._lastScrollTop = null;
    this._element = null;
  }
}
