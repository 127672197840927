import { Controller } from "./controller"

const controller = new Controller();

export default {
  Controller,
  start: function() {
    controller.start()
  }
};
