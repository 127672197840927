import { Backdrop } from './backdrop'
import { Aside } from './aside'
import { Menu } from './menu'

export class Controller {
  constructor() {
    window.Turbolinks = window.Turbo
    this.backdrop = new Backdrop()
    this.aside = new Aside(this.backdrop)
    this.menu = new Menu(this.backdrop)
  }

  start () {
    this.init()
    this.attachEventListeners()
  }

  init() {
    this.aside.init()
    this.menu.init()
  }

  attachEventListeners() {
    this.aside.attachEventListeners()
    this.menu.attachEventListeners()
  }
}
