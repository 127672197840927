export class DoubleTap {
  noop = () => {}

  constructor (container, {
    onDoubleTap,
    isEnabled
  }) {
    this.touchTime = 0;
    this.container = container;
    this.isEnabled = isEnabled;
    this.onDoubleTap = onDoubleTap || this.noop;
  }

  handler = (e) => {
    if(!this.isEnabled()) return;

    if (this.touchTime === 0) {
      this.touchTime = Date.now();
      this.point = {
        x: e.pageX,
        y: e.pageY,
      };
    }
    else if (Date.now() - this.touchTime < 500 &&
             Math.abs(e.pageX - this.point.x) < 50 &&
             Math.abs(e.pageY - this.point.y) < 50) {
      this.onDoubleTap(e);
      this.touchTime = 0;
    } else {
      this.touchTime = 0;
    }
  }

  init () {
    this.container.addEventListener('click', this.handler);
  }

  destroy() {
    this.container.removeEventListener('click', this.handler);
  }
}
