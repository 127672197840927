import Dropzone from "dropzone";

export class Controller {
  constructor() {
    Dropzone.autoDiscover = false;
    this._element = null;
  }

  start () {
    this.attachEventListeners()
  }

  init() {
    this.clearDropzone();
    this.initDropzone();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  clearDropzone() {
    if(!this._element)
      return

    const attachableType = this._element.getAttribute("data-attachable-type")
    const attachableId = this._element.getAttribute("data-attachable-id")

    if(!this._element?.isConnected ||
       this._attachableType != attachableType ||
       this._attachableId != attachableId
      ) {

      this._dropzone.destroy()
      this._element = null
      this._attachableType = null
      this._attachableId = null
    }
  }

  initDropzone() {
    let elementId = "documents-upload-area";
    let element = document.getElementById(elementId);

    if (!element)
      return;

    if (this._element == element)
      return;

    this._element = element;

    let dropzone = new Dropzone(
      this._element,
      {
        timeout: 0,
        hiddenInputContainer: '#page-sidebar-documents'
      }
    );

    let csrf = document.querySelector("meta[name='csrf-token']").content;
    this._attachableType = element.attributes["data-attachable-type"].value;
    this._attachableId = element.attributes["data-attachable-id"].value;

    dropzone.on("sending", (file, xhr, formData) => {
      formData.append("attachable_type", this._attachableType);
      formData.append("attachable_id", this._attachableId);
      xhr.setRequestHeader("X-CSRF-Token", csrf);
    });
    dropzone.on("success", (file, responseText, e) => {
      const container = $("#page-sidebar-documents")
      container.append(responseText.data)
      container[0].querySelectorAll('img[data-preview-url]').forEach((img) => {
        if(!img.src) {
          img.src = img.dataset.previewUrl;
        }
      });

    });

    this._dropzone = dropzone;
  }
}
