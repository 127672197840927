class Geolocation {
  static init() {
    if(!window.geolocation) {
      window.geolocation = new this()
      window.geolocation.start()
    }
  }

  start() {
    navigator.geolocation.watchPosition(
      this.onSuccess,
      this.onError
    )
  }

  onSuccess(position) {
    const {
      latitude,
      longitude,
      accuracy
    } = position.coords

    var dateTime = new Date()
    var time = dateTime.getTime()
    var expireTime = time + 300
    dateTime.setTime(expireTime)

    document.cookie =
      'latitude'+'='+latitude+';expires='+dateTime.toUTCString()+';path=/'
    document.cookie =
      'longitude'+'='+longitude+';expires='+dateTime.toUTCString()+';path=/'
    document.cookie =
      'accuracy'+'='+accuracy+';expires='+dateTime.toUTCString()+';path=/'
  }

  onError() {
  };
}

export { Geolocation }
