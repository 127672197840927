export class UnsupportedViewer {
  constructor(controller, doc) {
    const contentType = doc.contentType;
    const span = document.createElement("span");
    span.classList.add("text-info", "text-center");
    span.innerHTML = `
      <p>
        We don't support ${contentType}.<br/>Download the document to view it.
      </p>
    `;
    this._element = span;
    controller._body.innerHTML = '';
    controller._body.appendChild(span)
    controller.changed();
  }

  destroy() {
    this._element.remove();
    this._element = null;
  }

  // toolbar
  supportsZoom() {
    return false;
  }

  supportsRotate() {
    return false;
  }

  supportsPaging() {
    return false;
  }

  isLoaded() {
    return true;
  }
}
