import { Document } from "./document";

export class Collection {
  constructor(container) {
    const elements = Collection._elements(container);
    this._documents = new Array(elements.length)
    for(let i = 0; i < elements.length; i++) {
      this._documents[i] = new Document(elements[i]);
    }
    this._eventListeners = [];
    this._index = 0;
  }

  static _elements(container) {
    if (container.classList.contains('document-viewer')) {
      return [container];
    }
    else {
      return container.querySelectorAll(
        '[data-toggle="document-viewer"]'
      );
    }
  }

  destroy() {
  }

  get documents() {
    return this._documents.values();
  }

  get index() {
    return this._index;
  }

  get total() {
    return this._documents.length;
  }

  get current() {
    return this._documents[this._index];
  }

  setCurrent(id) {
    const nIndex = this._documents.findIndex((d) => d.id == id);
    if(this._index != nIndex) {
      this._index = nIndex;
      this._changed();
    }
  }

  get canPrevious() {
    return this._index > 0;
  }

  get canNext() {
    return this._index < this.total - 1;
  }

  previous() {
    if(this.canPrevious) {
      this._index -= 1;
      this._changed();
    }
  }

  next() {
    if(this.canNext) {
      this._index += 1;
      this._changed();
    }
  }

  onChange(fn) {
    this._eventListeners.push(fn)
  }
  // private

  _changed() {
    const event = new CustomEvent('document:changed', {
      detail: {
        collection: this,
        document: this.current
      }
    });

    this._eventListeners.forEach((fn) => fn(event));
  }
}
