import { Modal } from "./modal";
import { Embeded } from "./embeded";
import { Collection } from "./collection";

export class Controller {
  constructor() {
    this._modals = new Map();
    this._documentViewer = null;
  }

  start() {
    this.attachEventListeners();
  }

  cleanup() {
    this._modals.forEach((modal) => {
      modal.destroy();
    });
    this._modals.clear();

    if(!!this._documentViewer && !this._documentViewer.container.isConnected) {
      this._documentViewer.destroy() ;
      this._documentViewer = null;
    }
  }

  attachEventListeners() {
    document.addEventListener('turbo:load', () => {
      this.cleanup();
      this.createDocumentViewer();
    });
    $(document).on('click', 'img[data-toggle="document-viewer"]', (event) => {
      this.show(event.target);
    });
  }

  show(img) {
    const container = img.closest("[data-document-viewer]")
    const name = container.attributes["data-document-viewer"].value;
    const documentId = img.attributes['data-id'].value;

    if(!this._modals.has(name)) {
      const coll = new Collection(container);
      this._modals.set(name, new Modal(coll));
    }

    const modal = this._modals.get(name);
    modal.collection.setCurrent(documentId);
    modal.show();
  }

  createDocumentViewer() {
    if(!!this._documentViewer)
      return;

    const container = document.querySelector("[data-type='document-viewer']")
    if (!container)
      return;

    const collection = new Collection(container);

    //const documentId = container.attributes['data-id'].value;
    this._documentViewer = new Embeded(container, collection);
  }

}
