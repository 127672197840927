import { Utils } from './utils'
class ActiveTabs {
  static init() {
    if(!window.activeTabs) {
      window.activeTabs = new this()
      window.activeTabs.init()
    }
  }

  init() {
    document.addEventListener("turbo:before-fetch-request", (event)=> {
      const src = Utils.lastRenderedLocation()
      const srcPath = src.pathname
      const dstUrl = event.detail.url
      const dstPath = dstUrl.pathname
      let activeTabs = {}
      if(src.href == dstUrl) {
        activeTabs = this.getActiveTabs(document)
      }
      else if (srcPath == dstPath){
        activeTabs = this.getActiveTabs(document)
      }
      else if(event.target instanceof HTMLFormElement) {
        activeTabs = this.getActiveTabs(document)
      }

      const value = Object.keys(activeTabs).map( (k) => {
        return `${k}:${activeTabs[k]}`
      }).join(",")
      const headers = event.detail.fetchOptions.headers
      headers["X-Active-Tabs"] = value
    })
  }

  getActiveTabs(doc) {
    const activeTabs = {}
    this.getPreservedActiveTabContainers(doc).forEach((container) => {
      const containerId = container.id
      const activeTabId = this.getActiveTabId(doc, containerId)
      activeTabs[containerId] = activeTabId
    })
    return activeTabs
  }

  getPreservedActiveTabContainers(doc) {
    return doc.querySelectorAll('.page-section [id][data-preserve-active-tab]') || []
  }

  getActiveTabId(doc, id) {
    const link = doc.querySelector(
      `#${id} .active[data-toggle='tab'][href^='#']`
    )
    const tab = doc.querySelector(link.attributes["href"].value)
    return tab.id
  }
}

export { ActiveTabs }
