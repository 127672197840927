export class Scroller {
  noop = () => {}

  constructor (container, {
    onScroll,
    isEnabled
  }) {
    this.container = container;
    this.isEnabled = isEnabled;
    this.onScroll = onScroll || this.noop;
  }

  handler = (e) => {
    if(!this.isEnabled()) return;

    e.preventDefault();

    const delta = Math.max(
        -1,
      Math.min(1, e.wheelDelta || -e.detail || -e.deltaY)
    );

    this.onScroll(e, { delta: delta });
  }

  init () {
    this.container.addEventListener('wheel', this.handler);
  }

  destroy() {
    this.container.removeEventListener('wheel', this.handler);
  }
}
