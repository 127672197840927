import { ImageViewer } from './image-viewer';
import { PdfViewer } from './pdf-viewer';
import { UnsupportedViewer } from './unsupported-viewer';
import { Toolbar } from './toolbar';

export class ViewerController {
  constructor(modal) {
    this._modal = modal;
    this._body = modal._body;
    this._footer = modal._footer;
    this._eventListeners = [];
    this._toolbar = new Toolbar(this);

    this.create(modal._collection);
  }

  create(collection) {
    this.createViewer(collection.current);

    collection.onChange((evt) => {
      this.createViewer(evt.detail.document, this.element);
    })
  }

  refresh() {
    this._modal.refresh();
  }

  get viewer() {
    return this._viewer;
  }

  destroy() {
    if(this._toolbar) this._toolbar.destroy();
    if(this._viewer) this._viewer.destroy();
    this._viewer = null;
    this._body = null;
    this._footer = null;
    this._toolbar = null;
    this._modal = null;
  }

  getViewerClass(doc) {
    const contentType = doc.contentType;
    if(contentType.startsWith("image/"))
      return ImageViewer;
    else if(contentType == "application/pdf")
      return PdfViewer;
    else
      return UnsupportedViewer;
  }

  createPreviewImage(doc) {
    if(!this._body) return;

    const width = (this._body.clientWidth) ||
                  (window.innerWidth * 0.9);
    const height = (this._body.clientHeight * 0.98) ||
                   (window.innerHeight * 0.9);
    const img = new Image();
    img.src = doc.previewUrl;
    img.width = width;
    img.height = height;
    img.style.objectFit = "contain";
    img.style.filter = "blur(0.4rem)";
    this._body.innerHTML = "";
    this._body.appendChild(img);
  }

  createViewer(doc) {
    if(this._viewer) {
      this._viewer.destroy();
    }

    this.createPreviewImage(doc);
    const viewerClass = this.getViewerClass(doc);
    this._viewer = new viewerClass(this, doc)

    this.changed();

    return this._viewer;
  }

  changed() {
    const event = new CustomEvent('viewer:changed', {
      detail: {
        viewer: this
      }
    });

    this._eventListeners.forEach((fn) => fn(event));
  }

  onChange(fn) {
    this._eventListeners.push(fn)
  }
}
