import { Toolbar } from './toolbar';
import { ViewerController } from './viewers';
import { KeyboardShortcuts } from "./keyboard-shortcuts";

export class Embeded {
  constructor(container, collection) {
    this._container = container;
    this._collection = collection;
    this.create();
    this._keyboardShortcuts = new KeyboardShortcuts(this);
  }

  get container() {
    return this._container;
  }

  get collection() {
    return this._collection;
  }

  get isAttached() {
    return this.container.isAttached;
  }

  show() {
    $(this.container).modal({backdrop: false});
  }

  hide() {
    $(this.container).modal('hide');
  }

  create() {
    const containerId = this._container.id;
    this._header = document.getElementById(`${containerId}-header`);
    this._body = document.getElementById(`${containerId}-body`);
    this._footer = document.getElementById(`${containerId}-footer`);

    // this._toolbar = new Toolbar(this.collection);
    //this._header.appendChild(this._toolbar.element);

    this._onResize = () => { this.refresh() };
    window.addEventListener('resize', this._onResize);
    this.refresh();
    this._viewerController = new ViewerController(this);
  }

  destroy() {
    if(this._onResize) {
      window.removeEventListener('resize', this._onResize);
    }
    this._keyboardShortcuts.destroy();
    //this._toolbar.destroy();
    this._viewerController.destroy();
    this._collection.destroy();

    this._container = null;
    this._body = null;
    this._footer = null
    this._header = null;
  }

  _updateBodySize() {
    const appHeader = document.getElementById('app-header');
    const pageInner = document.querySelector(".page-inner");
    //const width = pageInner.clientWidth;
    const height = this._container.offsetParent.clientHeight -
          this._container.offsetTop -
          this._header.clientHeight -
          this._footer.clientHeight -
          20;

    //this._body.style.width = `${width}px`;
    this._body.style.height = `${height}px`;
  }

  refresh() {
    this._updateBodySize();
  }
}
