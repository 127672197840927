import { ScrollUtils } from "../scroll-utils";

export class Tab {
  constructor(key) {
    this._lastScrollTop = 0;
    this._key = key;
    this._element = document.querySelector(this._key);
    this._scrollContainer = this._element.querySelector('.overflow-y-auto');
    if(!this._scrollContainer)
      this._scrollContainer = this._element;

    this._autoScroll = this._scrollContainer.getAttribute('data-scroll')

    const scrollTargetAttr =
          this._scrollContainer.getAttribute('data-scroll-target');
    if(scrollTargetAttr) {
      this._scrollTarget = document.getElementById(scrollTargetAttr.value);
    }
    else {
      this._scrollTarget = this._scrollContainer;
    }
  }

  get key() {
    return this._key;
  }

  isAttached() {
    return this._element.isConnected &&
      this._scrollContainer.isConnected &&
      this._scrollTarget.isConnected;
  }

  updateLastScrollTop() {
    this._lastScrollTop = this._scrollTarget.scrollTop;
  }

  scroll(force) {
    switch(this._autoScroll) {
    case "bottom":
      ScrollUtils.scrollBottom(this._scrollTarget);
      break;
    case "into":
      ScrollUtils.scrollInto(this._scrollTarget);
      break;
    default:
      this._scrollTarget.scrollTop = this._lastScrollTop;
      break;
    }
    //this._autoScroll = false;
  }

  setActive(active) {
    if(active)
      this._element.classList.add("active");
    else
      this._element.classList.remove("active");
  }

  lazyLoadImages() {
    this._element.querySelectorAll('img[data-preview-url]').forEach((img) => {
      img.src = img.dataset.previewUrl;
    });
  }
  destroy() {
    this._lastScrollTop = null;
    this._link = null;
    this._element = null;
    this._scrollContainer = null;
    this._scrollTarget = null;
  }
}
