import flatpickr from "flatpickr";

export class DatePickers {
  constructor() {
    this._pickers = []
  }

  static start() {
    if(!window.DatePickers) {
      window.DatePickers = new this();
      window.DatePickers.start();
    }
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.create();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
    // hack to fix the keyboard shortcut for modals
    window.addEventListener('keydown', (e) => {
      const isEscPressed = (e.keyCode == 27);
      if (!isEscPressed)
        return;

      const openedPickrs = $('.flatpickr-input.active');
      const isFlatPickrOpen = openedPickrs.length;
      if (!isFlatPickrOpen)
        return;

      e.stopPropagation();
      openedPickrs[0]._flatpickr.close();

      return;
    }, true);
  }

  cleanup() {
    this._pickers
      .filter((p) => (
        !p.isConnected ||
          !p._flatpickr ||
          !p._flatpickr.innerContainer ||
          !p._flatpickr.innerContainer.isConnected
      ))
      .forEach((p) => {
        if (p._flatpickr) {
          p._flatpickr.destroy();
          delete p._flatpickr;
        }
        const pos = this._pickers.indexOf(p);
        this._pickers.splice(pos, 1);
      });
  }

  create() {
    const pickers = document.querySelectorAll(
      'input[data-toggle="flatpickr"]'
    );

    pickers
      .forEach((p) => {
        if(!p._flatpickr) {
          const data = p.dataset
          const options = {
            minuteIncrement: 1,
            plugins: [],
            disable: data.disables ?? [],
            defaultDate: data.defaultDates ?? null,
            disableMobile: true,
          }

          // flatpickr plugins
          if (data.confirmdate) {
            options.plugins.push(new confirmDatePlugin({
              showAlways: true
            }))
          }
          if (data.weekselect) {
            options.plugins.push(new weekSelect({}))
          }
          if (data.monthselect) {
            options.plugins.push(new monthSelectPlugin({
              shorthand: true, //defaults to false
              dateFormat: 'm/y', //defaults to 'F Y'
              altFormat: 'F Y' //defaults to 'F Y'
            }))
          }
          if (data.rangeplugin) {
            options.plugins.push(
              new range({ input: `#${options.secondInput}` })
            )
          }

          flatpickr(p, options)

          this._pickers.push(p);
        }
      })
  }
}
