import {
  Slider,
  Pinch,
  DoubleTap,
  Scroller
} from './events';

const SCALE_MAX = 8;
const SCALE_MIN = 0.5;

export class ImageViewer {
  constructor(controller, doc) {
    this._controller = controller;
    this._url = doc.url;
    this._state = {
      rotation: 0,
      scale: 1,
      containerSize: {
        w: 0,
        h: 0
      },
      imageSize: {
        w: 0,
        h: 0
      },
      position: {
        x: 0,
        y: 0
      },
      offset: {
        x: 0,
        y: 0
      }
    };
    this._isLoaded = false;
    this._eventListeners = [];
    this._container = this._createContainer();
    this._image = this._createImage();
    this._imageSlider = this._createImageSlider();
    this._pinch = this._createPinch();
    this._doubleTap = this._createDoubleTap();
    this._scrollZoom = this._createScrollZoom();
    this._onResizeHandler = () => { this._refresh(); }
  }

  get state() {
    return this._state
  }

  _createContainer() {
    const div = document.createElement("div");
    div.classList.add(
      "d-flex",
      "overflow-hidden",
      "align-items-center",
      "justify-content-center"
    );
    div.style.touchAction = "none";
    return div;
  }

  _createImage() {
    const img  = new Image();
    img.style.objectFit = "contain";
    const onLoad = () => this._init();
    img.addEventListener('load', () => this._init());
    img.src = this._url;
    return img;
  }

  // Run after image is loaded
  _init () {
    if(this._destroyed)
      return;

    const body = this._controller._body;

    body.innerHTML = "";
    body.appendChild(this._container);
    this._container.appendChild(this._image);
    window.addEventListener("resize", this._onResizeHandler);
    this._imageSlider.init();
    this._pinch.init();
    this._doubleTap.init();
    this._scrollZoom.init();
    this._updateTransformations();
    this._isLoaded = true;
    this._controller.changed();
    this._refresh();
  }

  _calculateCurrentSize() {
    const { containerSize, imageSize} = this._state;
    const body = this._controller._body;

    const cStyle = window.getComputedStyle(body);

    containerSize.w = body.clientWidth -
      parseFloat(cStyle.paddingLeft) -
      parseFloat(cStyle.paddingRight);

    containerSize.h = body.clientHeight -
      parseFloat(cStyle.paddingTop) -
      parseFloat(cStyle.paddingBottom);

    imageSize.w = containerSize.w;
    imageSize.h = containerSize.h;
  }

  _updateElementsSize() {
    const { containerSize, imageSize} = this._state;

    this._calculateCurrentSize();
    this._container.style.width = `${containerSize.w}px`;
    this._container.style.height = `${containerSize.h}px`;

    this._image.style.width = `${imageSize.w}px`;
    this._image.style.height = `${imageSize.h}px`;
  }

  _createImageSlider() {
    return new Slider(this._container, {
      onMove: (eMove, data) => {
        const { position, offset } = this._state;
        position.x = data.dx + offset.x;
        position.y = data.dy + offset.y;
        this._updateTransformations();
      },
      onEnd: () => {
        const { position, offset } = this._state;
        offset.x = position.x;
        offset.y = position.y;
      },
      isEnabled: () => this._state.scale > 1
    })
  }


  _createPinch () {
    return new Pinch(this._container, {
      onMove: (eMove, data) => {
        const { scale } = this._state;
        this._zoom(scale + data.diff/2);
      },
      isEnabled: () => true
    })
  }

  _createDoubleTap() {
    return new DoubleTap(this._container, {
      onDoubleTap: () => {
        const { scale } = this._state;
        if(scale > 1) {
          this._zoom(1);
        }
        else {
          this._zoom(4);
        }
      },
      isEnabled: () => true
    })
  }

  _createScrollZoom(e) {
    return new Scroller(this._container, {
      onScroll: (e, data) => {
        const { scale } = this._state;
        this._zoom(scale + (data.delta * 0.25));
      },
      isEnabled: () => true
    });
  }

  _refresh() {
    if(this._controller) {
      this._controller.refresh();
      this._updateElementsSize();
    }
  }

  destroy () {
    window.removeEventListener("resize", this._onResize);

    if(this._imageSlider)
      this._imageSlider.destroy();

    if(this._pinch)
      this._pinch.destroy();

    if(this._doubleTap)
      this._doubleTap.destroy();

    if(this._scrollZoom)
      this._scrollZoom.destroy();

    if(this._image){
      this._image.remove();
      this._image = null;
    }

    if(this._container) {
      this._container.remove();
      this._container = null;
    }

    this._controller = null;

    this._destroyed = true;
  }

  _updateTransformations() {
    const {scale, rotation, position, imageSize} = this._state;

    let x = position.x;
    let y = position.y;

    if(rotation == 0.25) {
      x = position.y;
      y = position.x * -1;
    }
    else if(rotation == 0.5) {
      x = x * -1;
      y = y * -1;
    }
    else if (rotation == 0.75) {
      x = position.y * -1;
      y = position.x;
    }

    x = x/scale;
    y = y/scale;

    this._image.style.transform = [
      `scale(${scale}, ${scale})`,
      `rotate(${rotation}turn)`,
      `translate3d(${x}px,${y}px, 0)`
    ].join(' ');
  }

  _zoom(value) {
    if(value < SCALE_MIN)
      value = SCALE_MIN;
    else if(value > SCALE_MAX)
      value = SCALE_MAX;

    this._state.scale = value;
    if(this._state.scale == 1) {
      const { position, offset } = this._state;
      position.x = 0;
      position.y = 0;
      offset.x = 0;
      offset.y = 0;
    }
    this._updateTransformations();
    this._controller.changed();
  }

  _rotate(value) {
    if (value < 0 ) {
      value = 1 + value
    }
    else if (value == 1) {
      value = value - 1;
    }
    this._state.rotation = value;
    this._updateTransformations();
    this._controller.changed();
  }

  // Used by toolbar

  supportsZoom() {
    return true;
  }

  supportsRotate() {
    return true;
  }

  supportsPaging() {
    return false;
  }

  isLoaded() {
    return this._isLoaded;
  }

  canZoomIn() {
    const { scale } = this._state;
    return  scale < SCALE_MAX;
  }

  canZoomOut() {
    const { scale } = this._state;
    return scale > SCALE_MIN;
  }

  zoomIn() {
    const { scale } = this._state;
    this._zoom(scale + 1);
  }

  zoomOut() {
    const { scale } = this._state;
    this._zoom(scale - 1);
  }

  rotateLeft() {
    const { rotation } = this._state;
    this._rotate(rotation - 0.25);
  }

  rotateRight() {
    const { rotation } = this._state;
    this._rotate(rotation + 0.25);
  }
}
