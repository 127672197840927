import { DateTime } from 'luxon'

class MomentAgo {
  static start() {
    if(!window.momentAgo) {
      window.momentAgo = new this()
      window.momentAgo.start()
    }
  }

  start() {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", () => this.init());
    } else {
      this.init();
    }
  }

  init() {
    this.update()
    this.attachEventListeners()
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.update());
    setInterval(() => this.update(), 6000)
  }

  update() {
    this.updateAgo()
  }

  updateAgo() {
    for(const element of document.querySelectorAll('[data-moment-ago]')) {
      const momentAgo = DateTime.fromISO(element.getAttribute('data-moment-ago'))
      element.innerHTML = momentAgo.toRelative()
    }
  }
}

export { MomentAgo }
