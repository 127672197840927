import { Backdrop } from "./backdrop"
import { Utils } from "../utils.js"

export class Controller {
  constructor() {
    this.backdrop = new Backdrop()
  }

  start () {
    this.init()
    this.attachEventListeners()
  }

  init() {
    this.turbo = window.Turbo
    this.backdrop.create()
    this._page = document.querySelector('.page')
    this._app = document.querySelector('.app')
    this._page_section = document.querySelector('.page-section')
    this._sidebar = document.querySelector('.page-sidebar')
    this.updateVisible()
  }

  attachEventListeners() {
    window.addEventListener('resize', () => this.update())
    document.addEventListener("turbo:load", () => this.init())
    // document.addEventListener("turbo:before-visit", () => this.hide())
    document.addEventListener("turbo:before-fetch-request", (event)=> {
      if (this.page && this.hasSidebar) {
        const headers = event.detail.fetchOptions.headers
        const value = this.isExpanded ? "expanded" : "collapsed"
        headers["X-Sidebar-State"] = value
      }
    })

    $(document).on(
      'click',
      '[data-toggle="sidebar"], .sidebar-backdrop', (e) => {
        e.preventDefault()
        const action = $(e).data('sidebar') ?? 'toggle'
        this.dispatch(action)
      })
  }

  get hasSidebar() {
    return this.page.classList.contains('has-sidebar')
  }

  get isExpanded() {
    return this.page.classList.contains('has-sidebar-expand-xl')
  }

  get isOpen() {
    return this.page.classList.contains('has-sidebar-open')
  }

  get page() {
    return this._page
  }

  get pageSection() {
    return this._page_section;
  }

  get app() {
    return this._app
  }

  get sidebar() {
    return this._sidebar
  }

  dispatch(action) {
    switch(action) {
    case 'show':
      this.open()
      break
    case 'hide':
      this.close()
      break
    default:
      this.toggle()
    }
  }

  disableSidebarTransition() {
    if(!this.sidebar) return

    this.sidebar.classList.add('notransition')
    setTimeout(() => this.sidebar.classList.remove('notransition'), 1)
  }

  update() {
    if(!this.page) return;

    if(Utils.isToggleScreenXlUp()) {
      this.hide()
    }

    this.updateVisible()
    this.disableSidebarTransition()
  }

  updateVisible() {
    if(!this.page) return

    const isExpanded = this.isExpanded
    const isOpen = this.isOpen
    const visible =  (Utils.isToggleScreenXlUp() ? isExpanded : isOpen)

    if(visible)
      this.app.classList.add('sidebar-visible')
    else
      this.app.classList.remove('sidebar-visible')
  }

  show() {
    this.backdrop.show()
    this.page.classList.add('has-sidebar-open')
    this.pageSection.classList.add('overflow-hidden')
    this.updateVisible()
  }

  hide() {
    this.backdrop.hide()
    this.page.classList.remove('has-sidebar-open')
    this.pageSection.classList.remove('overflow-hidden')
    this.updateVisible()
  }

  expand() {
    this.page.classList.add('has-sidebar-expand-xl')
    this.updateVisible()
  }

  collapse() {
    this.page.classList.remove('has-sidebar-expand-xl')
    this.updateVisible()
  }

  toggle() {
    if(Utils.isToggleScreenXlUp()) {
      if(this.isExpanded)
        this.collapse()
      else
        this.expand()
    }
    else {
      if(this.isOpen)
        this.hide()
      else
        this.show()
    }
  }
}
