import { ScrollUtils } from "./scroll-utils";

export class DeepLink {
  static start() {
    if(!window.DeepLink) {
      window.DeepLink = new this();
      window.DeepLink.start()
    }
  }

  start() {
    this.attachEventListeners()
    this.init()
  }

  init() {
    this.toggleDeepLink()
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => { this.init() })
  }

  toggleDeepLink() {
    document.querySelectorAll(".deep-link").forEach((link) => {
      link.classList.remove("deep-link");
    });

    const url = new URL(window.location.href)
    const hash = url.hash
    const param = url.searchParams.get("dl")
    if (!!!hash && !!!param)
      return;

    const selector = hash || ("#" + param)
    let el = document.querySelector(selector)
    if (!!!el)
      return;

    el.classList.add("deep-link");

    el.focus();

    ScrollUtils.scrollInto(el);
  }
}
