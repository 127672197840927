class BrowserDetector {
  /**
   * Mobile Browser
   */
  static isMobile() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      return true
    }
    else {
      return false
    }
  }
}

export { BrowserDetector }
