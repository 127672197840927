export class Reactive {
  constructor() {
  }

  static start() {
    if(!window.Reactive) {
      window.Reactive = new this();
      window.Reactive.start();
    }
  }

  start() {
    this.attachEventListeners();
  }

  attachEventListeners() {
    $(document).on("change", 'input[data-reactive], select[data-reactive]', function(evt) {
      const form = evt.currentTarget.form
      const field = evt.currentTarget.dataset.reactive
      const action = form.action

      form.action += "?_type=reactive&field=" + field;

      if (form.dataset.validated == "true") {
        form.action += "&_form_validated=true";
      }
      form.requestSubmit()
    })

  }
}
