import { Utils } from '../utils'

export class Aside {
  constructor(backdrop) {
    this.selector = '.app-aside'
    this.toggleSelector = '[data-toggle="aside"]'
    this.backdrop = backdrop
  }

  init() {
    this.backdrop.init()
    this.update()
  }

  attachEventListeners() {
    window.addEventListener('resize', () => this.update())
    document.addEventListener("turbo:load", () => {
      this.hide()
      this.init()
    })
    $(document).on(
      'click',
      '[data-toggle="aside"], .aside-backdrop',
      (e) => {
        e.preventDefault()
        this.toggle()
      })
  }

  getAside() {
    return document.querySelector(this.selector)
  }

  getToggleElements() {
    return document.querySelectorAll(this.toggleSelector)
  }

  show() {
    this.backdrop.show()
    const aside = this.getAside();
    if(aside)
      aside.classList.add('show')
    this.getToggleElements().forEach((e) => e.classList.add('active'))
  }

  hide() {
    this.backdrop.hide()
    const aside = this.getAside();
    if(aside)
      aside.classList.remove('show')
    this.getToggleElements().forEach((e) => e.classList.remove('active'))
  }

  toggle() {
    const isShown = this.getAside().classList.contains('show')
    if(isShown) this.hide()
    else this.show()
    this.update()
  }

  update() {
    const app = document.querySelector('.app')
    const aside = this.getAside()

    if(!aside) return

    if (Utils.isToggleScreenUp() &&
        aside.classList.contains('has-open') &&
        !app.classList.contains('has-fullwidth')) {
      this.hide()
    }

    aside.classList.add('notransition')
    setTimeout(() => aside.classList.remove('notransition'), 1)
  }
}
