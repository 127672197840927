export class Document {
  constructor(element) {
    this._id = element.attributes["data-id"].value;
    this._name = element.attributes["data-name"].value;
    this._contentType = element.attributes["data-content-type"].value;
    this._previewUrl = element.attributes["data-preview-url"].value;
    this._url = element.attributes["data-url"].value;
    this._filename = element.attributes["data-filename"].value;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get contentType() {
    return this._contentType;
  }

  get previewUrl() {
    return this._previewUrl;
  }

  get url() {
    return this._url;
  }

  get filename() {
    return this._filename;
  }
}
