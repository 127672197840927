import * as Turbo from '@hotwired/turbo'
import {DOMUtils} from './dom-utils.js'

class TurbolinkHacks {
  constructor() {
    this.turbo = Turbo
    // This is a hack for stacked menu
    window.Turbo.supported = true
    window.Turbolinks = window.Turbo

  }

  applyHacks() {

    addEventListener("turbo:before-render", (event) => {
      event.detail.render = (currentElement, newElement) => {
        DOMUtils.replace(currentElement, newElement)
      }
    })

    this.turbo.StreamActions.render = function () {
      const snapshot = Turbo.PageSnapshot.fromHTMLString(
        this.templateElement.innerHTML
      )
      Turbo.session.view.renderPage(snapshot, false, true)
    }

    this.turbo.StreamActions.replace = function () {
      try {
        const content = this.firstChild.content
        const container = this.getAttribute("container")
        const target = this.getAttribute("target")
        const childrenOnly = (this.getAttribute("children-only") == "true")
        DOMUtils.appendOrReplace(container, target, content, childrenOnly)
      } catch (error) {
        console.error(error)
      }
    }

    this.turbo.StreamActions.script = function () {
      const scripts = this.firstChild.content.querySelectorAll("script")
      for(const script of scripts) {
        window.eval(script.text)
      }
    }
    $(document).on("turbo:before-fetch-request", (evt)=> {
      const e = evt.originalEvent;
      const activeElement = e.target;
      const headers = e.detail.fetchOptions.headers
      headers["X-Last-Rendered-Url"] =
        this.turbo.session.view.lastRenderedLocation.toString()

      if(document.body.hasAttribute("data-layout-name")) {
        headers["X-Layout-Name"] =
          document.body.getAttribute("data-layout-name")
      }
      if(activeElement.hasAttribute("data-no-permanent")) {
        headers["X-No-Permanent"] =
          activeElement.getAttribute("data-no-permanent")
      }
    })
  }
}

(function(){
  if(!window.TurboHacks) {
    window.TurboHacks = new TurbolinkHacks()
    window.TurboHacks.applyHacks()
  }
})()
