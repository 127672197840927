import IMask from 'imask';

class MaskedInputs {
  static init() {
    if(!window.maskedInputs) {
      window.maskedInputs = new this()
      window.maskedInputs.init()
    }
  }

  init() {
    this.createMaskedInputs()
    $(document).on("turbo:load", () => this.createMaskedInputs() )
  }

  getElements() {
    return $('input[data-type="mask"]') || []
  }

  createMaskedInputs() {
    this.getElements().each((idx, element) => {
      let data = element.dataset;
      let initialized = this.isInitialized(element);
      if (!initialized) {
        this.create(element, data)
      }
      else {
        if(element.mask == data.mask) {
          element.iMask.updateValue();
        }
        else {
          this.destroy(element)
          this.create(element, data)
        }
      }
    })
  }

  isInitialized(element) {
    return !!element.iMask;
  }

  destroy(element) {
    element.iMask.destroy()
  }

  create(element, data) {
    element.mask = data.mask;

    let options = {}
    if (data.mask == "integer") {
      options.mask = Number
      options.scale = 0
    }
    else if (data.mask == "numeric") {
      options.mask = Number
      options.scale = data.scale
      options.thousandsSeparator = ','  // any single char
      options.radix = '.'  // fractional delimiter
    }
    else if (data.mask == "percentage") {
      options.mask = Number
      options.scale = data.scale
      options.thousandsSeparator = ','  // any single char
      options.radix = '.'  // fractional delimiter
      options.min = 0
      options.max = 100
      options.parse = (str) => {
        return Number(str)/100;
      }
    }
    else if (data.mask == "feet") {
      options = {
        mask: "FT{-}IN",
        lazy: false,
        blocks: {
          FT: {
            mask: Number,
            min: 0,
            scale: 0,
          },
            IN: {
              mask: Number,
              min: 0,
              max: 11,
              scale: 0
            }
        },
        parse: (str) => {
          const parts = str.split('-');
          const feet = Number(parts[0])*12;
          const inches = Number(parts[1]);
          return feet + inches;
        }
      }
    }
    else if (data.mask == "phone") {
      options = {
        mask: '{+1} (000) 000-0000'
      }
    }
    element.iMask = IMask(element, options)
  }
}

export { MaskedInputs }
