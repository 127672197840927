const pdfjsLoader = () => require("pdfjs-dist/webpack")
import { Utils } from "../../utils";
import {
  Slider,
  Pinch,
  DoubleTap,
  Scroller
} from './events';

const SCALE_MAX = 8;
const SCALE_MIN = 0;
const SCALE_STEP = 0.25;
const SCALE_INITIAL = 1;

export class PdfViewer {
  constructor(controller, doc) {
    this._controller = controller;
    this._url = doc.url;
    this._state = {
      scaleInitial: SCALE_INITIAL,
      scaleMin: SCALE_MIN,
      scaleMax: SCALE_MAX,
      scaleStep: SCALE_STEP,
      scale: SCALE_INITIAL,
      rotation: 0,
      page: 1,
      numPages: 1,
      lastRenderedPage: 0,
      containerSize: {
        w: 0,
        h: 0
      },
      position: {
        x: 0,
        y: 0
      },
      offset: {
        x: 0,
        y: 0
      }
    }
    this._isLoaded = false;
    this._container = this._createContainer();
    this._slider = this._createSlider();
    this._pinch = this._createPinch();
    this._doubleTap = this._createDoubleTap();
    this._scrollZoom = this._createScrollZoom();
    this._onResizeHandler = () => this._resize();
    this._updateElementsSize();
    this._loadDocument();
  }

  _calculateCurrentSize() {
    const { containerSize } = this._state;
    const body = this._controller._body;

    const cStyle = window.getComputedStyle(body);

    containerSize.w = body.clientWidth -
      parseFloat(cStyle.paddingLeft) -
      parseFloat(cStyle.paddingRight);

    containerSize.h = body.clientHeight -
      parseFloat(cStyle.paddingTop) -
      parseFloat(cStyle.paddingBottom);
  }

  _updateElementsSize() {
    const { containerSize } = this._state;

    this._calculateCurrentSize();
    this._container.style.width = `${containerSize.w}px`;
    this._container.style.height = `${containerSize.h}px`;
  }

  destroy() {
    window.removeEventListener('resize', this._onResizeHandler);
    if(this._slider) {
      this._slider.destroy();
      this._slider = null;
    }

    if(this._pinch) {
      this._pinch.destroy();
      this._pinch = null;
    }

    if(this._doubleTap) {
      this._doubleTap.destroy();
      this._doubleTap = null;
    }

    if(this._scrollZoom) {
      this._scrollZoom.destroy();
      this._scrollZoom = null;
    }

    if(this._state.canvasContext) {
      this._state.canvasContext = null;
    }
    if(this._state.canvas) {
      this._state.canvas = null;
    }

    if(this._container) {
      this._container.remove();
      this._container = null;
    }

    this._destroyed = true;
  }

  _resize() {
    if(this._isLoaded && !this._destroyed) {
      this._renderPage();
    }
  }

  _loadDocument() {
    const pdfjsLib = pdfjsLoader();
    pdfjsLib.getDocument(this._url).promise.then((pdf) => {
      this._init(pdf);
    });
  }

  _createContainer() {
    const div = document.createElement("div");
    div.classList.add(
      "d-flex",
      "overflow-hidden",
      "align-items-center",
      "justify-content-center"
    );
    div.style.touchAction = "none";
    return div;
  }

  _createSlider() {
    return new Slider(this._container, {
      onMove: (eMove, data) => {
        const { position, offset } = this._state;
        position.x = data.dx + offset.x;
        position.y = data.dy + offset.y;
        this._renderPage();
      },
      onEnd: () => {
        const { position, offset } = this._state;
        offset.x = position.x;
        offset.y = position.y;
        this._renderPage();
      },
      isEnabled: () => this._state.scale > this._state.scaleInitial
    })
  }

  _createPinch () {
    return new Pinch(this._container, {
      onMove: (eMove, data) => {
        const { scale } = this._state;
        this._zoom(scale + data.diff/2);
        this._renderPage();
      },
      isEnabled: () => true
    })
  }

  _createDoubleTap() {
    return new DoubleTap(this._container, {
      onDoubleTap: () => {
        const { scale, scaleInitial, scaleStep } = this._state;
        if(scale > scaleInitial) {
          this._zoom(scaleInitial);
        }
        else {
          this._zoom(scale + scaleStep*2);
        }
        this._renderPage();
      },
      isEnabled: () => true
    })
  }

  _createScrollZoom(e) {
    return new Scroller(this._container, {
      onScroll: (e, data) => {
        const { page } = this._state;
        /* this._zoom(scale + (data.delta * 0.25)); */
        this._changePage(page + data.delta * -1);
      },
      isEnabled: () => true
    });
  }

  _createCanvas() {
    const { position } = this._state;
    const canvas = document.createElement("canvas");
    canvas.classList.add(
      "d-block",
      "p-0",
      "border-top",
      "border-bottom",
      "border-left",
      "border-right"
    );
    canvas.style.margin = "auto";
    canvas.style.position = "absolute";
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.bottom = 0;
    canvas.style.right = 0;
    canvas.style.transform = `translate3d(${position.x}px,${position.y}px, 0)`
    return canvas;
  }

  _updateCanvas() {
    const { position } = this._state;
    const canvas = this._state.canvas;
    canvas.style.transform = `translate3d(${position.x}px,${position.y}px, 0)`
  }

  _clearCanvas(viewport) {
    if(!this._state.canvas)
      return;

    const canvas = this._state.canvas

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    this._state.canvasContext.clearRect(
      0,
      0,
      canvas.width,
      canvas.height,
    )
    //this._state.canvasContextcontext.beginPath();
  }

  _init(pdf) {
    if(this._destroyed) return;
    this._pdf = pdf;
    this._state.numPages = pdf.numPages;
    this._controller._body.innerHTML = null;
    this._controller._body.appendChild(this._container);
    this._slider.init();
    this._pinch.init();
    this._doubleTap.init();
    this._scrollZoom.init();
    window.addEventListener('resize', this._onResizeHandler)
    this._renderPage();
  }


  _renderPage() {
    if(!this._pdf) return;
    if(!!this._state.isRendering) return;
    this._state.isRendering = true

    this._pdf.getPage(this._state.page).then((page) => {
      if(this._destroyed) return;

      if(page.pageNumber != this._state.page) return;

      if (!this._state.canvas) {
        this._state.canvas= this._createCanvas();
        this._state.canvasContext = this._state.canvas.getContext('2d');
      }

      if(this._state.lastRenderedPage != this._state.page) {
        this._fitToPage(page);
      }

      this._updateCanvas();

      const viewport = page.getViewport({
        scale: this._state.scale,
        rotation: Math.trunc(this._state.rotation * 360)
      });

      if(!!this._state.lastRenderedPage ||
         this._state.lastRenderedPage != this._state.page) {
        this._clearCanvas(viewport);
      }


      page.render({
        canvasContext: this._state.canvasContext,
        viewport: viewport
      }).promise.then(() => {
        if(page.pageNumber != this._state.page) return;

        if(this._state.lastRenderedPage != this._state.page) {
          this._container.innerHTML = "";
          this._container.appendChild(this._state.canvas);
        }

        this._isLoaded = true;
        this._controller.changed();
        this._controller.refresh();
        this._updateElementsSize();
        this._state.lastRenderedPage = this._state.page;
        this._state.isRendering = false
      });
    });
  }

  _fitToPage(page) {
    if(this._state.lastRenderedPage != this._state.page &&
       this._state.containerSize.h < page.view[3]) {
      this._state.scaleInitial =
        Utils.round(this._state.containerSize.h/page.view[3], 2);
      this._state.scaleMin =
        Utils.round(this._state.scaleInitial - (this._state.scaleStep*2), 2);

      if(this._state.scaleMin < 0)
        this._state.scaleMin = 0;

      this._state.scaleMax =
        Utils.round(this._state.scaleInitial + (this._state.scaleStep*4), 2);
      this._zoom(this._state.scaleInitial)
    }
  }

  _zoom(value) {
    if(value < this._state.scaleMin)
      value = this._state.scaleMin;
    else if(value > this._state.scaleMax)
      value = this._state.scaleMax;

    this._state.scale = value;
    if(this._state.scale == this._state.scaleInitial) {
      const { position, offset } = this._state;
      position.x = 0;
      position.y = 0;
      offset.x = 0;
      offset.y = 0;
    }
  }

  _rotate(value) {
    if (value < 0 ) {
      value = 1 + value
    }
    else if (value == 1) {
      value = value - 1;
    }
    this._state.rotation = value;
    this._renderPage();
  }

  _changePage(value) {
    const { page, numPages } = this._state;
    if (value < 1) {
      value = 1;
    }
    else if (value > numPages) {
      value = numPages;
    }

    if(page == value) return;

    this._state.page = value;

    this._renderPage();
  }

  // Used by Toolbar
  supportsZoom() {
    return true;
  }

  supportsRotate() {
    return true;
  }

  supportsPaging() {
    return true;
  }

  isLoaded() {
    return this._isLoaded;
  }

  canZoomIn() {
    const { scaleMax, scale } = this._state;
    return  scale < scaleMax;
  }

  canZoomOut() {
    const { scale, scaleMin } = this._state;
    return scale > scaleMin;
  }

  zoomIn() {
    const { scaleStep, scale } = this._state;
    this._zoom(scale + scaleStep);
    this._renderPage();
  }

  zoomOut() {
    const { scaleStep, scale } = this._state;
    this._zoom(scale - scaleStep);
    this._renderPage();
  }

  rotateLeft() {
    const { rotation } = this._state;
    this._rotate(rotation - 0.25);
  }

  rotateRight() {
    const { rotation } = this._state;
    this._rotate(rotation + 0.25);
  }

  canNext() {
    const { page, numPages } = this._state;
    return page < numPages;
  }

  canPrevious() {
    const { page, numPages } = this._state;
    return page > 1;
  }

  nextPage() {
    this._changePage(this._state.page + 1);
  }

  previousPage() {
    this._changePage(this._state.page - 1);
  }
}
