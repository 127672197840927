import { Controller } from "./controller"

const controller = new Controller

export default {
  controller,
  start: function() {
    controller.start()
  }
}
