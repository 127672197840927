window.$ = window.jQuery = require('jquery');
global.$ = require('jquery');
require("jquery-ui/dist/jquery-ui")
require("bootstrap")
require("leaflet")
require("leaflet-ant-path")
require("looper-base/turbolinks-hacks")
require("dropzone")
import Chart from 'chart.js/auto';
window.Chart = Chart;

require("looper-base/theme")