import { Toolbar } from './toolbar';
import { ViewerController } from './viewers';
import { KeyboardShortcuts } from "./keyboard-shortcuts";

export class Modal {
  constructor(collection) {
    this._collection = collection;
    this.create();
    this.attach();
    this._keyboardShortcuts = new KeyboardShortcuts(this);
  }

  get container() {
    return this._container;
  }

  get collection() {
    return this._collection;
  }

  get isAttached() {
    return this.container.isAttached;
  }

  show() {
    $(this.container).modal({backdrop: false});
  }

  hide() {
    $(this.container).modal('hide');
  }

  create() {
    this._container = this.createContainer();

    const dialog = this.createDialog();
    this._container.appendChild(dialog);

    const content = this.createModalContent();
    dialog.appendChild(content);

    this._header = this.createModalHeader();
    content.appendChild(this._header);

    this._toolbar = new Toolbar(this.collection);
    this._header.appendChild(this._toolbar.element);

    this._body = this.createModalBody();
    this._footer = this.createModalFooter();
    content.appendChild(this._body);
    content.appendChild(this._footer);

    this._onResize = () => { this.refresh() };
    window.addEventListener('resize', this._onResize);

    $(this._container).on('shown.bs.modal', () => {
      this.refresh();
      if(!this._viewerController) {
        this._viewerController = new ViewerController(this);
      }
    });
  }

  attach() {
    const app = document.querySelector('.app');
    app.appendChild(this.container);
  }

  destroy() {
    if(this._onResize) {
      window.removeEventListener('resize', this._onResize);
    }
    $(this.container).modal('dispose');
    this.container.remove();
    this._keyboardShortcuts.destroy();
    this._toolbar.destroy();
    this._viewerController.destroy();
    this._collection.destroy();

    this._body = null;
    this._container = null;
    this._footer = null
    this._header = null;
  }

  createContainer() {
    const container = document.createElement("div");
    container.classList.add("modal", "fade");
    container.setAttribute("role", "dialog");
    container.setAttribute("tabindex", -1);
    container.setAttribute("aria-modal", true);
    return container;
  }

  createDialog() {
    const dialog = document.createElement("div");
    dialog.classList.add("modal-dialog", "modal-fullscreen");
    dialog.setAttribute("role", "document");
    return dialog;
  }

  createModalContent() {
    const content = document.createElement("div");
    content.classList.add("modal-content");
    return content;
  }

  createModalHeader() {
    const header = document.createElement("div");
    header.classList.add("modal-header", "p-0");
    return header;
  }

  createModalBody() {
    const body = document.createElement("div");
    body.classList.add(
      "modal-body",
      "overflow-hidden",
      "justify-content-center",
      "align-items-center"
    );
    return body;
  }

  createModalFooter() {
    const body = document.createElement("div");
    body.classList.add("modal-footer", "p-0");
    return body;
  }

  _updateBodySize() {
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight -
                   this._header.clientHeight -
                   this._footer.clientHeight;

    this._body.style.width = `${width}px`;
    this._body.style.height = `${height}px`;
  }

  refresh() {
    this._updateBodySize();
  }
}
