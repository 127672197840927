export class CurrentLink {
  static start(containers) {
    if(!window.CurrentLink) {
      window.CurrentLink = new this(containers)
      window.CurrentLink.start()
    }
  }

  constructor(containers) {
    this.containers = containers || []
  }

  start() {
    this.attachEventListeners()
    this.init()
  }

  init() {
    this.toggleCurrentLinkClass()
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => { this.init() })
  }

  toggleCurrentLinkClass() {
    const currentPath = window.location.pathname
    this.containers.forEach((container) => {
      const selector = `#${container} a[data-current-link][href^='/']`
      document.querySelectorAll(selector).forEach((link) => {
        const path = (new URL(link.href)).pathname
        if(currentPath == path) {
          link.classList.add("current-link")
        }
        else {
          link.classList.remove("current-link")
        }
      })
    })
  }
}
