export class Pinch {
  noop = () => {}

  constructor (container, {
    onStart, onMove, onEnd, isEnabled,
  }) {
    this.container = container;
    this.isEnabled = isEnabled;
    this.onStart = onStart || this.noop;
    this.onMove = onMove || this.noop;
    this.onEnd = onEnd || this.noop;
  }

  startHandler = (eStart) => {
    if (!this.isEnabled()) return;

    if(eStart.touches.length != 2) return;

    eStart.preventDefault();

    this.removeListeners();

    const { moveHandler, endHandler, onStart } = this;

    this.startDist = this.getTouchPointsDistance(eStart.touches);

    onStart(eStart, { startDist: startDist });

    // add listeners
    document.addEventListener('touchmove', moveHandler);
    document.addEventListener('touchend', endHandler);
    /*
       add end handler in context menu as well.
       As mouseup event is not trigger on context menu open
       https://bugs.chromium.org/p/chromium/issues/detail?id=506801
     */
    document.addEventListener('contextmenu', endHandler);
  }

  moveHandler = (eMove) => {
    if (!this.isEnabled()) return;

    eMove.preventDefault();

    const { startDist } = this;
    const newDist = getTouchPointsDistance(eMove.touches);

    onMove(eMove, {
      startDist: startDist,
      newDist: newDist,
      diff: newDist - startDist
    });
  }

  endHandler = () => {
    if (!this.isEnabled()) return;
    this.removeListeners();
    this.onEnd();
  }

  getTouchPointsDistance (touches) {
    const touch0 = touches[0];
    const touch1 = touches[1];
    return Math.sqrt(
      Math.pow(touch1.pageX - touch0.pageX, 2) +
      Math.pow(touch1.pageY - touch0.pageY, 2)
    );
  }
  // remove previous events if its not removed
  // - Case when while sliding mouse moved out of document and released there
  removeListeners () {
    document.removeEventListener('touchmove', this.moveHandler);
    document.removeEventListener('touchend', this.endHandler);
    document.removeEventListener('contextmenu', this.endHandler);
  }

  init () {
    this.container.addEventListener('touchstart', this.startHandler);
  }

  destroy () {
    this.container.removeEventListener('touchstart', this.startHandler);
    this.removeListeners();
  }
}
