export class ClipboardUtils {
  constructor() {
    this._buttons = []
  }

  static start() {
    if(!window.ClipboardUtils) {
      window.ClipboardUtils = new this();
      window.ClipboardUtils.start();
    }
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.create();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => this.init());
  }

  cleanup() {
    this._buttons.forEach((btn) => {
      btn.removeEventListener("click", this._handler);
      const pos = this._buttons.indexOf(btn);
      this._buttons.splice(pos, 1);
    });
  }

  create() {
    const buttons = document.querySelectorAll(
      'button[data-clipboard]'
    );
    buttons.forEach((btn) => {
      btn.addEventListener("click", this._handler);
      this._buttons.push(btn);
    })
  }

  _handler(event) {
    const btn = event.currentTarget;
    const data = btn.dataset.clipboard;
    navigator.clipboard.writeText(data);
    $(btn).tooltip({
      title: 'Coppied!',
      boundary: 'window',
      trigger: 'manual',
    });
    $(btn).tooltip('show');
    setTimeout(() => {
      $(btn).tooltip('hide');
    }, 700);
  }
}
