export class Backdrop {
  constructor() {
    this.selector = '.sidebar-backdrop'
  }

  getBackdrop() {
    return document.querySelector(this.selector)
  }

  getPage() {
    return document.querySelector('.page')
  }

  create() {
    if(this.getBackdrop()) return

    const page = this.getPage()
    if(!page) return

    const backdrop = document.createElement("div")
    backdrop.classList.add("sidebar-backdrop")
    page.prepend(backdrop)
  }

  show() {
    const backdrop = this.getBackdrop()
    if(!backdrop) return

    backdrop.classList.add('show')
  }

  hide() {
    const backdrop = this.getBackdrop()
    if(!backdrop) return

    backdrop.classList.remove('show')
  }
}
