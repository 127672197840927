import { Action, ActionGroup } from "../actions";

export class DocumentActions {
  constructor(collection) {
    this._collection = collection;
    this._actionGroup = new ActionGroup({
      classes: ["mr-auto"],
      actions: [
        this.createDownload(collection),
        this.createView(collection),
      ]
    });
  }

  get element() {
    return this._actionGroup.element;
  }

  destroy() {
    this._actionGroup.destroy();
  }

  createDownload(collection) {
    const action = new Action({
      elementType: "a",
      text: "Download",
      attributes: {
        "href": collection.current.url,
        "download": collection.current.filename,
      },
      icon: {
        name: "download"
      }
    });

    collection.onChange((evt) => {
      const document = evt.detail.document;
      action.element.setAttribute("href", document.url);
      action.element.setAttribute("download", document.filename);
    })

    return action;
  }

  createView(collection) {
    const linkProvider = (document) => {
      return `/up/documents/${document.id}`
    }
    const action = new Action({
      elementType: "a",
      text: "View",
      attributes: {
        "href": linkProvider(collection.current),
        "target": "_blank",
        "data-turbo": "false"
      },
      icon: {
        name: "external-link-alt"
      }
    });

    collection.onChange((evt) => {
      const document = evt.detail.document;
      action.element.setAttribute("href", linkProvider(document));
    })

    return action;
  }
}
